import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import Nav from 'react-bootstrap/Nav';

import { FaDiscord, FaTwitter, FaInstagram } from 'react-icons/fa';

import styles from '../styles/Home.module.scss';

const Home = () => {
  return (
    <div className="blue-gradient">
      <div className={styles.hero}>
        <div className="container">
          <div className="py-5 text-center">
            <Image
              src="/noundles.svg"
              alt="Noundles"
              width={800}
              height={180}
            />
            <div className="py-3">
              <a
                href="https://opensea.io/collection/noundles?search[toggles][0]=BUY_NOW"
                className="btn btn-lg btn-primary rounded-pill px-4 m-2"
                target="_blank"
                rel="noreferrer"
              >
                Buy on OpenSea
              </a>

              <a
                href="https://discord.gg/noundles"
                className="btn btn-lg btn-dark rounded-pill px-4 m-2"
                target="_blank"
                rel="noreferrer"
              >
                Discord
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-5 d-flex flex-column justify-content-end">
              <Image src="/greg.png" alt="Greg" width={894} height={894} />
            </div>
            <div className="col-12 col-md-7 d-flex flex-column justify-content-center order-first order-md-last">
              <h2 className="display-2 title mb-3">Our Mission</h2>
              <p className="lead">
                Noundles is a community-led PFP collection inspired by Nouns
                with a twist of smoothed out funk. We aspire to make NFTs more
                collaborative, innovative and transparent through the power of
                the $RAINBOW.
              </p>
              <p className="my-3">
                <a
                  href="https://opensea.io/collection/noundles?search[toggles][0]=BUY_NOW"
                  className="btn btn-lg btn-primary rounded-pill px-4"
                  target="_blank"
                  rel="noreferrer"
                >
                  Buy on OpenSea
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Image
          src="/genesis.png"
          alt="Genesis Noundles"
          layout="responsive"
          width={2400}
          height={432}
        />
      </div>
      <div className="container pt-5">
        <section className="my-5">
          <div className="row">
            <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
              <Image
                src="/game.png"
                alt="The Game"
                width={1120}
                height={1560}
              />
            </div>
            <div className="col-12 col-md-6 d-flex flex-column justify-content-center order-first order-md-last">
              <h2 className="display-2 title mb-3">The Game</h2>
              <p className="lead">
                Noundles is a PvE game played on the Ethereum blockchain. Every
                day, Noundles earn 4 $RAINBOWS and Frendles earn 2 $RAINBOWS.
                Ruggles earn their keep by stealing $RAINBOWS from the Noundles community.
              </p>
              <p className="my-3">
                <Link href="/stake" passHref>
                  <a className="btn btn-lg btn-dark rounded-pill px-4">
                    Play the game
                  </a>
                </Link>
              </p>
            </div>
          </div>
        </section>
      </div>
      <div className={styles.rainbow}>
        <div className="container py-5">
          <div className="row">
            <div className="col-12 col-lg-8 offset-lg-2">
              <section className="my-5 text-center">
                <h2 className="display-2 title mb-3 text-pink">Rainbow Shop</h2>
                <p className="lead">
                  Noundle holders automatically earn rainbows as part of our
                  innovative, gasless staking technology. No expensive staking
                  and unstaking - just automatic rewards for owning a Noundle,
                  Frendle or Ruggle!
                </p>
                <p className="lead">
                  $RAINBOWS are your ticket to the Rainbow Shop, where you can
                  purchase desirable NFTs from other collections, secure coveted
                  whitelist spots to upcoming projects, buy personalized
                  Noundles merchandise and more! 75% of Noundles royalties fund
                  the Rainbow Shop and keep the shelves stocked for you to go on
                  your shopping spree.
                </p>
                <p className="my-3">
                  <Link href="/shop" passHref>
                    <a className="btn btn-lg btn-pink rounded-pill px-4">
                      Visit the Rainbow Shop
                    </a>
                  </Link>
                </p>
              </section>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.social}>
        <div className="container py-5">
          <section className="my-5 d-flex flex-column align-items-center">
            <h2 className="display-2 title mb-3">Join us!</h2>
            <Nav className="display-4">
              <Nav.Item>
                <Nav.Link
                  href="https://twitter.com/YourRainbowShop"
                  target="_blank"
                  className="text-pink"
                >
                  <FaTwitter />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  href="https://instagram.com/noundlesnft"
                  target="_blank"
                  className="text-pink"
                >
                  <FaInstagram />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  href="https://discord.gg/noundles"
                  target="_blank"
                  className="text-pink"
                >
                  <FaDiscord />
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Home;
